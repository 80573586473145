<template>
  <div v-if="token_login" :class="$style['wrapper'] + ' ' + $style['token']">
    <ion-spinner color="primary"/>
  </div>
  <div v-else :class="$style['wrapper']">
    <ion-back-button v-if="screen!=='login'&&screen!=='reset_success'" :text='$t("auth.login_form.action_label")'
                     defaultHref="/login"
                     @click="(e)=>{e.preventDefault(); screen='login'}"/>

    <div v-if="screen !== 'reset_success'" :class="$style['logo']">
      <LogoT :class="$style['icon']"/>
    </div>

    <form v-if="screen === 'login'" @submit.prevent="loginAction">
      <ion-item>
        <ion-label position="stacked">
          {{ $t("auth.login_form.email.label") }}
        </ion-label>
        <ion-input
            v-model="email"
            :disabled="loading"
            :placeholder="$t('auth.login_form.email.placeholder')"
            type="email"
        ></ion-input>
      </ion-item>

      <ion-item>
        <ion-label position="stacked">
          {{ $t("auth.login_form.password.label") }}
        </ion-label>

        <ion-input
            v-model="password"
            :disabled="loading"
            :placeholder="$t('auth.login_form.password.placeholder')"
            type="password"
        ></ion-input>

        <ion-button
            slot="end"
            color="medium"
            fill="clear"
            style="height: 4em"
            @click="screen = 'reset'"
        >
          {{ $t("auth.login_form.reset_label") }}
        </ion-button>
      </ion-item>

      <ion-button
          :class="$style['submit']"
          :disabled="loading || !email || !password"
          color="primary"
          expand="full"
          size="large"
          type="submit"
          @click.prevent="loginAction"
      >
        <span v-if="!loading"> {{ $t("auth.login_form.action_label") }}</span>
        <ion-spinner v-else></ion-spinner>
      </ion-button>
    </form>

    <form v-else-if="screen === 'reset'" @submit.prevent="resetAction">
      <ion-item>
        <ion-label position="stacked">
          {{ $t("auth.login_form.email.label") }}
        </ion-label>
        <ion-input
            v-model="email"
            :disabled="loading"
            :placeholder="$t('auth.login_form.email.placeholder')"
            type="email"
        ></ion-input>
      </ion-item>

      <ion-button
          :class="$style['submit']"
          :disabled="loading || !email"
          color="primary"
          expand="full"
          size="large"
          type="submit"
          @click.prevent="resetAction"
      >
        <span v-if="!loading"> {{ $t("auth.reset_form.action_label") }}</span>
        <ion-spinner v-else></ion-spinner>
      </ion-button>
    </form>

    <div v-else-if="screen === 'reset_success'" :class="$style['success']">
      <ion-icon
          :class="$style['icon']"
          :icon="checkmarkOutline"
          color="success"
      />
      <ion-text align="center">
        <h1>{{ $t("auth.reset_form.success_title") }}</h1>
        <p>{{ $t("auth.reset_form.success_message") }}</p>
      </ion-text>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { IonBackButton, IonButton, IonIcon, IonInput, IonItem, IonLabel, IonSpinner } from "@ionic/vue";
import LogoT from "@/components/icons/LogoT.vue";
import { checkmarkOutline } from "ionicons/icons";

export default defineComponent({
  components: {
    IonInput,
    IonSpinner,
    LogoT,
    IonIcon,
    IonItem,
    IonLabel, IonBackButton,
    IonButton,
  },
  setup() {
    return {checkmarkOutline};
  },
  data() {
    return {
      screen: "login",
      failed_tries: 0,
      loading: false,
      token_login: false,
      email: "",
      password: "",
    };
  },
  mounted() {
    const token = this.$route.query?.token;
    const email = this.$route.query?.email;
    const hash = this.$route.query?.hash;
    const organization_id = this.$route.query?.organization_id;

    if (token && email && hash) {
      localStorage.removeItem("user_token");

      this.token_login = true;
      this.axios
          .post("user/login", {
            token: token,
            email: email,
            hash: hash,
            organization_id: organization_id
          })
          .then((resp) => {
            this.successLogin(resp, {source: "email"});
          })
          .catch(() => {
            this.token_login = false;
          });

      // Remove query parameters from url
      this.$router.push({
        path: this.$route.path,
        query: {},
      });
    }
  },
  methods: {
    loginAction(): void {
      if (this.loading) {
        return;
      }

      this.loading = true;

      this.axios
          .post("user/login", {
            email: this.email,
            password: this.password,
          })
          .then((resp) => {
            this.successLogin(resp);
          })
          .catch(() => {
            this.failed_tries++;
            this.loading = false;
          });
    },
    resetAction(): void {
      if (this.loading) {
        return;
      }

      this.loading = true;

      this.axios
          .post("user/reset", {
            email: this.email,
          })
          .then(() => {
            this.screen = "reset_success";
            setTimeout(() => {
              // Refresh window
              window.location.reload();
            }, 5000);
          })
          .catch(() => {
            this.loading = false;
          });
    },
    successLogin(resp: { data?: { token?: string } }, query_parameters?: Record<string, string>): void {
      const token = resp.data?.token;
      if (token) {
        localStorage.setItem("user_token", token);

        const url = new URL(window.location.href);
        if (query_parameters) {
          Object.keys(query_parameters).forEach((key) => {
            url.searchParams.append(key, query_parameters[key]);
          });
        }
        window.location.href = url.toString();
      }
    },
  },
});
</script>

<style lang="scss" module>
.wrapper {
  padding-top: var(--ion-safe-area-top);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.token {
    justify-content: center;
    align-items: center;
  }

  [mode="ios"] & {
    ion-item {
      --padding-start: 4px;
      --padding-end: 4px;
    }
  }

  align-items: flex-start;
  flex-grow: 1;

  form {
    margin-top: auto;
    width: 100%;
  }
}

.logo {
  padding: 2em;
  color: var(--ion-color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;;
  height: 75%;

  pointer-events: none;

  svg {
    width: 1em;
    height: auto;
    max-width: 100%;
  }
}

.icon {
  font-size: 5em;
}

.submit {
  margin-top: 1em;
}

.success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  .icon {
    color: var(--ion-color-success);
    font-size: 8em;
  }
}
</style>
