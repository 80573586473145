<template>
  <label v-if="!value||!value.length" :class="$style['field']">
    <input
        :accept="accept"
        :capture="capture"
        :disabled="loading||disabled"
        :multiple="multiple"
        type="file"
        @change="onFileChange"

    />
    <ion-button :disabled="loading||disabled">
      <ion-icon slot="icon-only" :icon="cloudUploadOutline"/>
    </ion-button>
  </label>

  <div v-else :class="$style['field']">
    <ion-img v-for="(file,index) in value_previews" :key="index" :class="$style['preview-image']" :src="file"/>
    <ion-button color="default" @click="remove">
      <ion-icon slot="icon-only" :icon="closeOutline" color="danger"/>
    </ion-button>
  </div>

</template>

<style lang="scss" module>
.field {
  display: flex;
  align-items: center;
  position: relative;

  input {
    opacity: 0;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

.preview-image {
  $size: 2em;
  height: $size;
  width: $size*2/3;
  object-fit: cover;
  object-position: top center;
  margin: .5em;
}
</style>

<script lang="ts">
import { defineComponent } from "vue";
import { IonButton, IonIcon, IonImg, loadingController } from '@ionic/vue';
import { checkmark, closeOutline, cloudUploadOutline } from "ionicons/icons";
import ImageResize from 'image-resize';

export default defineComponent({
  props: ["value", "accept", "capture", "multiple", 'params', 'disabled'],
  emits: ["change"],
  components: {
    IonImg,
    IonIcon,
    IonButton
  },
  data() {
    return {
      loading: false,
      value_previews: [] as string[]
    }
  },
  setup() {
    return {
      closeOutline, cloudUploadOutline, checkmark
    }
  },
  watch: {
    loading(to) {
      if (to) {
        loadingController.create({}).then((res) => {
          res.present();
        })
      } else {
        loadingController.dismiss();
      }
    },
    value(to) {
      this.value_previews = [];
      to.forEach((file: any) => {
        this.axios.get('storage/preview/' + file.file_name).then((response: any) => {
          this.value_previews.push(response.data);
        }).catch((error: any) => {
          console.error(error);
        });
      });
    }
  },
  methods: {
    remove() {
      this.$emit("change", []);
    },
    async onFileChange(event: any) {
      const files = event.target.files;
      if (!files || !files.length) {
        return;
      }

      this.loading = true;

      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        let file = files[i];

        if (file.type.match('image.*')) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore - TODO: Fix this
          const imageResize = new ImageResize({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore - TODO: Fix this
            format: 'jpg',
            width: 1250
          });

          const base64file = await imageResize
              .play(file)

          file = await (await fetch(base64file.toString()))?.blob();

          console.log('Resized image', file);
        }

        formData.append("files[]", file);
      }

      if (this.params) {
        for (const key in this.params) {
          formData.append(key, this.params[key]);
        }
      }

      this.axios.post('storage', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response: any) => {
        this.$emit("change", response.data);
        this.loading = false;
      }).catch((error: any) => {
        console.error(error);
        this.loading = false;
      });
    },
  }
});
</script>
